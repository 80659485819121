import { initStopAnimateOnResize } from '../../../../Common/Resources/src_front/js/components/initStopAnimateOnResize';
import { initScrollToAnchor } from '../../../../Common/Resources/src_front/js/components/initScrollToAnchor';
import { initMenus } from './components/initMenus';
import { initAccordion } from './components/initAccordion';
import { initSearchForm } from './components/forms/initSearchForm';
import { AnimationSearchModal } from './components/AnimationSearchModal';

document.addEventListener('DOMContentLoaded', () => {
    initSearchForm();
    initStopAnimateOnResize();
    initMenus();
    initAccordion();
    initScrollToAnchor({
        offset: 0,
        smooth: true,
        afterScrolling: function () {
            const input = document.querySelector('[data-search-account-form-input]');
            input?.focus();
        },
    });
});

// If the browser has cached the page
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        const modalSearchAnimation = new AnimationSearchModal();
        modalSearchAnimation.resetModalToDefaultState();
    }
});
