import { getCookie } from '../../../../../../Common/Resources/src_front/js/helper-functions/getCookie';
import { replaceRandomChars } from '../../utilities/replaceRandomChars';

const apiURL = window.usURL;

async function fetchData(url, method = 'GET', requestBody = {}, headers = {}) {
    try {
        const options = {
            method,
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: method !== 'GET' ? JSON.stringify(requestBody) : undefined,
        };

        const response = await fetch(url, options);
        return await response.json();
    } catch (error) {
        console.error('Fetch Error:', error);
        throw new Error('Failed to fetch data');
    }
}

export async function fetchDataParse(username) {
    const url = `${apiURL}/udi/api/v1/parse/${username}`;
    const fingerprintCookie = getCookie('fingerprint') || 'sdfe25wr34gsd45frth';
    let fingerprint = {
        fingerprint: replaceRandomChars(fingerprintCookie, 5, 10),
    };

    return await fetchData(url, 'POST', fingerprint);
}

export async function fetchProfile(username) {
    const url = `${apiURL}/udi/api/v1/profile/${username}`;
    return await fetchData(url);
}

export async function fetchPosts(username) {
    const url = `${apiURL}/udi/api/v1/post/${username}`;
    return await fetchData(url);
}

export async function fetchStory(username) {
    const url = `${apiURL}/udi/api/v1/story/${username}`;
    return await fetchData(url);
}

export async function fetchTaggedPosts(username) {
    const url = `${apiURL}/udi/api/v1/tagged-post/${username}`;
    return await fetchData(url);
}

export async function fetchComments(postId) {
    const url = `${apiURL}/udi/api/v1/comment/${postId}`;
    return await fetchData(url);
}
