import { isInstagramLink } from './utilities/isInstagramLink';
import { getUsernameFromLink } from './utilities/getUsernameFromLink';
import { AnimationSearchModal } from '../AnimationSearchModal';

export const initSearchForm = () => {
    // VARS
    const form = document.querySelector('[data-search-account-form]');
    if (!form) return;
    const input = form.querySelector('[data-search-account-form-input]');
    const modalSearchAnimation = new AnimationSearchModal();
    let userName = '';

    // LISTENERS
    form.addEventListener('submit', handleFormSubmit);

    input.addEventListener('focus', function () {
        removeError();
    });

    // HANDLERS
    function handleFormSubmit(event) {
        event.preventDefault();
        userName = input.value.trim();
        const isValid = validateForm().isValid;
        if (!isValid) return;
        modalSearchAnimation.startAnimation();
    }

    // FUNCTIONS
    function displayError(input, error) {
        const errorText = input.getAttribute(`data-error-${error}`);
        form.insertAdjacentHTML('beforeend', `<span class="error">${errorText}</span>`);
    }

    function removeError() {
        const error = form.querySelector('.error');
        if (error) {
            error.remove();
        }
    }

    function validateUsername(username) {
        const regex = /^[\w](?!.*?\.{2})[\w.]{1,}[\w]$/;
        return regex.test(username);
    }

    function validateForm() {
        const valid = {
            isValid: false,
            error: '',
        };

        if (userName.length < 3) {
            valid.error = 'required';
            displayError(input, valid.error);
        } else if (isInstagramLink(userName)) {
            const username = getUsernameFromLink(userName);

            if (validateUsername(username)) {
                userName = username;
                valid.isValid = true;
            } else {
                valid.error = 'invalid';
                displayError(input, valid.error);
            }
        } else if (validateUsername(userName)) {
            valid.isValid = true;
        } else {
            valid.error = 'invalid';
            displayError(input, valid.error);
        }

        return valid;
    }
};
