import HystModal from 'hystmodal';

export const handleFormResponse = (responseData, actionValue, modal, callBack) => {
    const username = responseData.username;
    const modalResponse = new HystModal({
        catchFocus: false,
        beforeOpen: function (modal) {
            const userNameEl = modal.openedWindow.querySelector('[data-modal-profile-not-found-username]');
            if (userNameEl) {
                userNameEl.textContent = username;
            }
        },
    });

    if (responseData.status === 'success' && username) {
        window.location.href = `${actionValue}?username=${encodeURIComponent(username)}`;
        return;
    } else if (responseData === 'Profile not found') {
        modal.close();
        modalResponse.open('[data-modal-profile-not-found]');
    } else if (responseData === 'Profile is private') {
        modal.close();
        modalResponse.open('[data-modal-profile-private]');
    } else {
        modal.close();
        console.error('Something went wrong');
    }

    callBack();
};
