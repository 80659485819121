import HystModal from 'hystmodal';
import anime from 'animejs/lib/anime.es.js';
import { isInstagramLink } from './forms/utilities/isInstagramLink';
import { getUsernameFromLink } from './forms/utilities/getUsernameFromLink';
import { handleFormResponse } from './forms/handleFormResponse';
import { fetchDataParse } from './forms/fetchData';

export function AnimationSearchModal() {
    const $this = this;
    const animationModal = document.querySelector('[data-animation-search-modal]');
    const form = document.querySelector('[data-search-account-form]');
    if (!animationModal || !form) return;
    const counter = animationModal.querySelector('[data-animation-search-modal-counter]');
    const input = form.querySelector('[data-search-account-form-input]');
    const activeClass = '--start-animation';
    const modal = new HystModal({
        closeOnEsc: false,
        closeOnOverlay: false,
        catchFocus: false,
    });

    $this.startAnimation = () => {
        $this.resetModalToDefaultState();
        modal.open('[data-animation-search-modal]');
        animationModal.classList.add(activeClass);
        let responseData;

        anime({
            targets: counter,
            innerHTML: [0, 100],
            easing: 'linear',
            round: 1,
            duration: 8000,
            begin: async function () {
                let userName = input.value.trim();

                if (isInstagramLink(userName)) {
                    userName = getUsernameFromLink(userName);
                }

                try {
                    responseData = await fetchDataParse(userName);
                } catch (error) {
                    $this.closeModal();
                    $this.resetModalToDefaultState();
                    console.error(error);
                }
            },
            complete: function () {
                if (responseData) {
                    handleFormResponse(
                        responseData,
                        form.getAttribute('action'),
                        modal,
                        $this.resetModalToDefaultState,
                    );
                }
            },
        });
    };

    $this.resetModalToDefaultState = () => {
        $this.closeModal();
        animationModal.classList.remove(activeClass);
        counter.textContent = '0%';
    };

    $this.closeModal = () => {
        modal.close();
    };
}
