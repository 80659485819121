import { setTabIndex } from '../utilities/setTabIndex';

export const initMenus = () => {
    // VARS
    const btn = document.querySelector('[data-menu-toggle]');
    const menu = document.querySelector('[data-menu]');
    const btns = document.querySelectorAll('[data-submenu-btn]');
    const submenus = document.querySelectorAll('[data-submenu]');
    if (!btn || !menu) return;
    const activeClass = '--is-open';

    // LISTENERS
    btn.addEventListener('click', function () {
        menu.classList.toggle(activeClass);
    });

    document.addEventListener('click', function (event) {
        if (!event.target.closest('[data-menu-toggle]') && !event.target.closest('[data-menu]')) {
            menu.classList.remove(activeClass);
        }

        if (!event.target.closest('[data-submenu-btn]') && !event.target.closest('[data-submenu]')) {
            submenus.forEach((menu) => {
                const links = menu.querySelectorAll('a');

                menu.classList.remove(activeClass);
                setTabIndex(links, '-1');
            });
        }
    });

    btns.forEach((button) => {
        button.addEventListener('click', function () {
            if (window.innerWidth < 768 && this.dataset.submenuBtn !== 'footer') return;
            const submenu = this.closest('[data-submenu]');
            const links = submenu.querySelectorAll('a');
            const activeSubmenu = document.querySelector(`.${activeClass}[data-submenu]`);

            if (activeSubmenu && submenu !== activeSubmenu) {
                const links = activeSubmenu.querySelectorAll('a');
                activeSubmenu.classList.remove(activeClass);
                setTabIndex(links, '-1');
            }

            submenu.classList.toggle(activeClass);

            submenu.classList.contains(activeClass) ? setTabIndex(links, '0') : setTabIndex(links, '-1');
        });
    });
};
