export function replaceRandomChars(str, min, max) {
    const strArray = str.split('');
    const numReplacements = Math.floor(Math.random() * (max - min + 1)) + min;
    const indices = [];

    while (indices.length < numReplacements) {
        const randomIndex = Math.floor(Math.random() * str.length);
        if (!indices.includes(randomIndex)) {
            indices.push(randomIndex);
            strArray[randomIndex] = getRandomChar();
        }
    }

    return strArray.join('');
}

function getRandomChar() {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    return chars[Math.floor(Math.random() * chars.length)];
}
